<template>
    <div class="row">
        <div class="col-xl-12">
            <div id="panel-1" class="panel">
                <div class="panel-container show">
                    <div class="panel-content">
                        <el-row :gutter="10">
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5">
                                <el-date-picker class="w-100 mt-3"
                                                v-model="form.date"
                                                type="week"
                                                :format="view"
                                                :picker-options="pickerOptions"
                                                value-format="yyyy-MM-dd"
                                                @change="changeDate"
                                                placeholder="Chọn tuần">
                                </el-date-picker>
                            </el-col>
                            <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5">
                                <el-button v-if="form.date" class="mt-3" type="primary" @click="AddScheduleDuty()">Đăng ký lịch trực</el-button>
                            </el-col>
                        </el-row>
                        <div class="mb-5 mt-5" v-if="form.date" >
                            <h4>Lịch đăng kí từ ngày <b class="text-primary">{{ form.start }}</b> đến ngày <b class="text-primary">{{ form.end }}</b></h4>
                        </div>
                        <!-- datatable start -->
                        <el-empty v-if="!form.date" description="Bạn chưa chọn ngày"></el-empty>
                        <el-row v-else v-loading="loading.loadingCalendar" class="table-responsive-lg">
                            <table class="table table-bordered m-0">
                                <thead>
                                <tr>
                                    <th class="action-date">
                                        <el-popover placement="right" width="200" v-model="visible.pre">
                                            <p class="text-break"><i class="el-icon-warning mr-2 text-warning" ></i>Bạn vẫn muốn tiếp tục khi chưa lưu thông tin?</p>
                                            <div style="text-align: right; margin: 0">
                                                <el-button size="mini" type="text" @click="visible.pre = false">Hủy</el-button>
                                                <el-button type="primary" size="mini" @click="visible.pre = false; preDate()">Tiếp tục</el-button>
                                            </div>
                                            <!--eslint-disable-->
                                            <i slot="reference" @click="checkVisible(false)" class="el-icon-arrow-left"></i>
                                        </el-popover>
                                    </th>
                                    <th class="text-center" :class="{
                                        'past': item.day < current,
                                        'current-date': item.day === current
                                    }" v-for="(item, key) in table.columns" :key="key">
                                        <span class="text-capitalize">{{ item.dayOfWeek }}</span> <br> {{ item.day }}
                                    </th>
                                    <th class="action-date">
                                        <el-popover placement="right" width="200" v-model="visible.next">
                                            <p class="text-break"><i class="el-icon-warning mr-2 text-warning" ></i>Bạn vẫn muốn tiếp tục khi chưa lưu thông tin?</p>
                                            <div style="text-align: right; margin: 0">
                                                <el-button size="mini" type="text" @click="visible.next = false">Hủy</el-button>
                                                <el-button type="primary" size="mini" @click="visible.next = false; nextDate()">Tiếp tục</el-button>
                                            </div>
                                            <!--eslint-disable-->
                                            <i slot="reference" @click="checkVisible(true)" class="el-icon-arrow-right"></i>
                                        </el-popover>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row" class="session"> Buổi sáng</th>
                                    <td class="text-center" :class="{
                                            'past': item.day < current,
                                            'current-date': item.day === current,
                                        }" v-for="(item, key) in table.columns" :key="key" :disabled="afternoonDisable.includes(item.day) || disableCheckbox(item.day)">
                                        <div  v-if="tableData.hasOwnProperty(item.day) && tableData[item.day]['morning'] " class="text-white">
                                            <div v-for="(value1, key1) in tableData[item.day]['morning']" :key="key1" >
                                                <a  @click="(currentUser.id == value1.created_user) && handleEdit(value1, 'morning', key1)" class="btn btn-primary btn-sm waves-effect waves-themed mb-2 mr-1">
                                                    {{ renderDate(value1) }}
                                                </a>
                                                <el-button slot="reference" class="btn btn-danger btn-sm waves-effect waves-themed mb-2" type="danger" size="small" @click="confirmDelete(value1)" v-if="currentUser.id == value1.created_user">
                                                    <i class="fal fa-trash-alt"></i>
                                                </el-button>
                                            </div>
                                        </div>
                                        <div v-else> 
                                            <label>Chưa có lịch </label>
                                        </div>
                                    </td>
                                    <th scope="row" class="session"> Buổi sáng</th>
                                </tr>
                                <tr>
                                    <th scope="row" class="session top"> Buổi chiều</th>
                                    <td class="text-center" :class="{
                                            'past': item.day < current,
                                            'current-date': item.day === current,
                                        }" v-for="(item, key) in table.columns" :key="key" :disabled="afternoonDisable.includes(item.day) || disableCheckbox(item.day)">
                                        <div  v-if="tableData.hasOwnProperty(item.day) && tableData[item.day]['afternoon'] " class="text-white">
                                            <div v-for="(value1, key1) in tableData[item.day]['afternoon']" :key="key1" >
                                                <a @click="(currentUser.id == value1.created_user) && handleEdit(value1, 'afternoon', key1)" class="btn btn-primary btn-sm waves-effect waves-themed mb-2 mr-1">
                                                    {{ renderDate(value1) }}
                                                </a>
                                                <el-button slot="reference" class="btn btn-danger btn-sm waves-effect waves-themed mb-2" type="danger" size="small" @click="confirmDelete(value1)" v-if="currentUser.id == value1.created_user">
                                                    <i class="fal fa-trash-alt"></i>
                                                </el-button>
                                            </div>
                                        </div>
                                        <div v-else> 
                                            <label>Chưa có lịch</label>
                                        </div>
                                    </td>
                                    <th scope="row" class="session top"> Buổi chiều</th>
                                </tr>
                                <tr>
                                    <th scope="row" class="session top"> Buổi tối</th>
                                    <td class="text-center" :class="{
                                            'past': item.day < current,
                                            'current-date': item.day === current,
                                        }" v-for="(item, key) in table.columns" :key="key" :disabled="eveningDisable.includes(item.day) || disableCheckbox(item.day)">
                                        <div  v-if="tableData.hasOwnProperty(item.day) && tableData[item.day]['evening'] " class="text-white">
                                            <div v-for="(value1, key1) in tableData[item.day]['evening']" :key="key1" >
                                                <a @click="(currentUser.id == value1.created_user) && handleEdit(value1, 'evening', key1)" class="btn btn-primary btn-sm waves-effect waves-themed mb-2 mr-1">
                                                    {{ renderDate(value1) }}
                                                </a>
                                                <el-button slot="reference" class="btn btn-danger btn-sm waves-effect waves-themed mb-2" type="danger" size="small" @click="confirmDelete(value1)" v-if="currentUser.id == value1.created_user">
                                                    <i class="fal fa-trash-alt"></i>
                                                </el-button>
                                            </div>
                                        </div>
                                        <div v-else> 
                                            <label>Chưa có lịch </label>
                                        </div>
                                    </td>
                                    <th scope="row" class="session top"> Buổi tối</th>
                                </tr>
                                </tbody>
                            </table>
                        </el-row>
                        <!-- datatable end -->
                    </div>
                </div>
            </div>
        </div>
        
        <el-dialog class="responsive" :visible.sync="dialogFormEditVisible" :title="dialog.title">
            <el-row :gutter="10" class="pr-2 pl-2">
                <table class="table m-0 table-borderless">
                    <tr>
                        <td>Thời gian bắt đầu (<span class="text-danger">*</span>) : </td>
                        <td>
                            <el-time-select placeholder="Start time" v-model="startTime"
                                :picker-options="{start: '00:00', step: '00:15', end: '23:59' }">
                            </el-time-select>
                        </td>
                    </tr>
                    <tr>
                        <td>Thời gian kết thúc (<span class="text-danger">*</span>) : </td>
                        <td>
                            <el-time-select placeholder="End time" v-model="endTime"
                                :picker-options="{start: '00:00', step: '00:15', end: '23:59' }">
                            </el-time-select>
                        </td>
                    </tr>
                </table>
                <button @click="submitEdit()" class="btn btn-info float-right"> Cập nhật</button> 
            </el-row>
        </el-dialog>
        <el-dialog class="responsive" :visible.sync="dialogFormAddVisible" :title="dialog.title">
            <el-row :gutter="10" class="pr-2 pl-2">
                <add-duty @completed="handleCompleted" :data="table.columns" :dialogFormAddVisible="dialogFormAddVisible"></add-duty>
            </el-row>
        </el-dialog>
    </div>

</template>



<script>
    import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
    import { SCHEDULE_DUTY, SCHEDULE_DUTY_UPDATE, SCHEDULE_DUTY_DELETE } from "@/core/services/store/service.module";
    import AddDuty from "@/views/pages/work/add-schedule-duty";
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);
    import {mapGetters} from "vuex";

    export default {
        name: "EdutalkLichTruc",
        components: {
            AddDuty
        },
        data() {
            return {
                loading: {
                    loadingCalendar: false,
                },
                fullScreen: {
                    isFullCalendar: false
                },
                pickerOptions: {
                    firstDayOfWeek: 1,
                },
                form: {
                    date: null,
                    start: null,
                    end: null,
                },
                table: {
                    columns: [],
                },
                current: null,
                morning: [],
                afternoon: [],
                evening: [],
                morningDisable: null,
                afternoonDisable: null,
                eveningDisable: null,
                view: null,
                visible: {
                    pre: null,
                    next: null
                },
                transform: {
                    morning: 'Buổi sáng',
                    afternoon: 'Buổi chiều',
                    evening: 'Buổi tối',
                },
                tableData: [],
                getData: null,
                dialogFormEditVisible: false,
                dialogFormAddVisible:false,
                dialog: {
                    title: null
                },
                startTime: '',
                endTime: '',
                schedule: [],
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Lịch trực", icon: 'far fa-calendar-alt'}
            ]);
            this.current = this.$moment().format('YYYY-MM-DD');
            this.form.date = this.$moment().format('YYYY-MM-DD');
            this.convertViewDate();
        },
        methods: {
            handleCompleted() {
                this.dialogFormAddVisible = false;
                this.reloadModel();
                // _.each(arr.arr, (v) => {
                //     let tempdate = v.event_date;
                //     if(v.type == "morning") {
                //         if ( this.tableData[tempdate] ) {
                //           if(this.tableData[tempdate]['morning'] ) {
                //             this.tableData[tempdate]['morning'].push(v)
                //             } else {
                //                 this.tableData[tempdate]['morning'] = [];
                //                 this.tableData[tempdate]['morning'].push(v)
                //             }
                //         } else {
                //             this.tableData[tempdate] = [];
                //             this.tableData[tempdate]['morning'] = [];
                //             this.tableData[tempdate]['morning'].push(v)
                //         }
                //     } else if ( v.type == "afternoon") {
                //         if ( this.tableData[tempdate] ) {
                //           if(this.tableData[tempdate]['afternoon'] ) {
                //             this.tableData[tempdate]['afternoon'].push(v)
                //             } else {
                //                 this.tableData[tempdate]['afternoon'] = [];
                //                 this.tableData[tempdate]['afternoon'].push(v)
                //             }
                //         } else {
                //             this.tableData[tempdate] = [];
                //             this.tableData[tempdate]['afternoon'] = [];
                //             this.tableData[tempdate]['afternoon'].push(v)
                //         }
                //     } else {
                //         if ( this.tableData[tempdate] ) {
                //           if(this.tableData[tempdate]['evening'] ) {
                //             this.tableData[tempdate]['evening'].push(v)
                //             } else {
                //                 this.tableData[tempdate]['evening'] = [];
                //                 this.tableData[tempdate]['evening'].push(v)
                //             }
                //         } else {
                //             this.tableData[tempdate] = [];
                //             this.tableData[tempdate]['evening'] = [];
                //             this.tableData[tempdate]['evening'].push(v)
                //         }
                //     }
                // })
            },
            renderDate(data) {
                return this.$moment(Date.parse(data.start_date)).format('HH:mm') + ' - ' + this.$moment(Date.parse(data.end_date)).format('HH:mm') + ' - ' + data.created_name; 
            },
            changeDate() {
                this.table.columns = [];
                this.convertViewDate();
            },
            convertViewDate() {
                if (!this.form.date) {
                    this.form.date = this.$moment().format('YYYY-MM-DD');
                }

                let begin = this.$moment(this.form.date, 'YYYY-MM-DD').startOf('week');
                let end = this.$moment(this.form.date, 'YYYY-MM-DD').endOf('week');
                this.form.start = begin.format('YYYY-MM-DD');
                this.form.end = end.format('YYYY-MM-DD');
                this.view = (this.form.start + ' đến ' + this.form.end);
                this.table.columns.push({ 'dayOfWeek': begin.format('dddd'), 'day': begin.format('YYYY-MM-DD'), 'times': [] });
                for (let i = 0; i < 6; i ++) {
                    let data = begin.add(1, 'days');
                    this.table.columns.push({ 'dayOfWeek': data.format('dddd'), 'day': data.format('YYYY-MM-DD'), 'times': []   })
                }
                this.reloadModel();
            },
            checkVisible(isNext) {
                this.visible.next = true;
                this.visible.pre = true;
                let morning = _.difference(this.morning, this.morningDisable);
                let afternoon = _.difference(this.afternoon, this.afternoonDisable);
                let evening = _.difference(this.evening, this.eveningDisable);
                if (morning.length === 0 && afternoon.length === 0 && evening.length === 0 ) {
                    if (isNext) {
                        this.nextDate();
                    } else {
                        this.preDate();
                    }
                } else {
                    if (isNext) {
                        this.visible.next = false;
                    } else {
                        this.visible.pre = false;
                    }
                }
            },
            nextDate() {
                this.loading.loadingCalendar = true;
                this.table.columns = [];
                let nextDate = this.$moment(this.form.date, 'YYYY-MM-DD').endOf('week').add(1, 'days');
                this.form.date = nextDate.format('YYYY-MM-DD');
                this.convertViewDate();

            },
            preDate() {
                this.loading.loadingCalendar = true;
                this.table.columns = [];
                let preDate = this.$moment(this.form.date, 'YYYY-MM-DD').startOf('week').subtract(1, 'days');
                this.form.date = preDate.format('YYYY-MM-DD');
                this.convertViewDate();
            },
            reloadModel: function () {
                this.clearSession();
                let param = this.mergeParams();
                this.loading.loadingCalendar = true;
                this.$store.dispatch(SCHEDULE_DUTY, param)
                    .then((response) => {
                        this.getData = response;
                        this.tableData = response;
                        this.loading.loadingCalendar = false;
                        // _.each(response, (value, key) => {
                        //     let temp= [];
                        //     if (value.morning) {
                        //         this.morning[key] = [];
                        //         _.each(value.morning, (value1) =>  {
                        //             temp.value = this.$moment(Date.parse(value1.start_date)).format('H:mm') + ' - ' + this.$moment(Date.parse(value1.end_date)).format('H:mm') + ' - ' + value1.created_name;
                        //             temp.id = value1.id,
                        //            this.morning[key].push(temp)
                                    
                        //         })
                        //     }
                    });
            },
            clearSession() {
                this.morning = [];
                this.afternoon = [];
                this.evening = [];
                this.morningDisable = [];
                this.afternoonDisable = [];
                this.eveningDisable = [];
            },
            mergeParams(customProperties) {
                let params = { };
                if (this.form.start) {
                    params = _.merge(params, {startDay: this.form.start})
                }
                if (this.form.end) {
                    params = _.merge(params, {endDay: this.form.end })
                }
                params = _.merge(params, customProperties);
                return params;
            },
            disableCheckbox(item) {
                return item < this.current || item === this.current
            },
            AddScheduleDuty() {
                this.dialogFormAddVisible = true;
                for (let i = 0; i < 7; i ++) {
                    this.table.columns[i].times = [];
                }
            },
            handleEdit(item, session, key) {
                this.dialog.title = `Sửa thông tin lịch trực: ${item.name}`;
                this.dialogFormEditVisible = true;
                this.startTime = this.$moment(Date.parse(item.start_date)).format('HH:mm');
                this.endTime = this.$moment(Date.parse(item.end_date)).format('HH:mm');
                this.schedule = item;
                this.schedule.session = session
                this.schedule.key = key
            },
            submitEdit() {
                if(this.startTime >= this.endTime) {
                    this.$notify({
                        title: 'Warning',
                        message: `Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc`,
                        type: 'warning'
                    });
                    return ;
                }
                let params = {
                    id: this.schedule.id,
                    start_time: this.startTime,
                    end_time: this.endTime,
                    event_date: this.schedule.date
                };
                this.$store.dispatch(SCHEDULE_DUTY_UPDATE, params)
                    .then((response) => {
                        if (response.errors) {
                            this.$notify({
                                title: 'Warning',
                                message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                                type: 'warning'
                            });
                        } else {
                            this.dialogFormEditVisible = false;
                            this.$message({
                                type: 'success',
                                message: 'Cập nhật thông tin thành công'
                            });
                            this.tableData[this.schedule.date][this.schedule.session][this.schedule.key].start_date = response.data.start_date
                            this.tableData[this.schedule.date][this.schedule.session][this.schedule.key].end_date = response.data.end_date
                        }
                    });
                    
            },
            confirmDelete(item) {
                this.$confirm('Xóa lịch trực. Tiếp tục?', 'Cảnh báo', {
                        confirmButtonText: 'Xóa',
                        cancelButtonText: 'Cancel',
                        type: 'warning',
                        center: true
                    }).then(() => {
                        this.$store.dispatch(SCHEDULE_DUTY_DELETE, {id: item.id})
                            .then((response) => {
                                if (response.errors) {
                                    this.$message({
                                        title: 'success',
                                        message: `${response.message}`,
                                        type: 'warning'
                                    });
                                } else {
                                    this.dialogFormEditVisible = false;
                                    this.$message({
                                        type: 'success',
                                        message: 'Xóa thành công'
                                    });
                                    // this.tableData   [item.date][session].splice(key, 1);
                                    this.reloadModel();
                                }
                            });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: 'Hủy xóa thành công'
                        });
                    });
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        }
    };

</script>
