<template>
    <div class="box-regiter-work">
        <h5>Thêm mới lịch trực: <b> {{ data[0].day }} đến  {{ data[6].day }}  </b></h5>
        <p style="color: red; font-style: oblique;">Thời gian bắt đầu - Ca sáng: 00:00 - 12:59, Ca chiều: 13:00 - 17:59, Ca tối: 18:00 - 23:59</p>
        <el-row v-for="(item, key) in data" :key="key" class="mb-2">
            <div class="btn btn-secondary waves-effect waves-themed text-uppercase p-1 mr-2">
                {{ item.dayOfWeek }}
                <button type="button" class="btn btn-light btn-xs"> {{ item.day }}</button>
            </div>
            <i class="el-icon-circle-plus" @click="addItem( item )"></i>
            <div class="list m-2" v-for="(it, k) in item.times" :key="k">
                <el-time-select placeholder="Start time" v-model="it.startTime"
                                :picker-options="{start: '00:00', step: '00:15', end: '23:59' }" class="mr-2">
                            </el-time-select>
                <el-time-select placeholder="End time" v-model="it.endTime"
                    :picker-options="{start: '00:00', step: '00:15', end: '23:59', minTime: it.startTime}" class="mr-2">
                </el-time-select>
                <i class="el-icon-remove" @click="removeItem(item, k )"></i>
            </div>
        </el-row>
        <button @click="submitStore()" class="btn btn-info float-right"> Lưu thông tin</button> 
    </div>
</template>
<script>
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    const _ = deepdash(lodash);
    import { mapGetters } from "vuex";
    import { SCHEDULE_DUTY_STORE } from "@/core/services/store/service.module";

    export default {
        name: "AddDuty",
        props: {
            update: {
                type: Boolean,
                default: false
            },
            confirm: {
                type: Boolean,
                default: false
            },
            join: {
                type: Boolean,
                default: false
            },
            data: [],
            dialogFormAddVisible: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                times: [],
                flag: true,
            }    
        },
        methods: {
            addItem(item) {
                item.times.push({ startTime: null, endTime: null});
            },
            removeItem(item, k) {
                item.times.splice(k, 1);
            },
            submitStore() {
                let arr = []
                _.each(this.data, (value, key) => {
                    if (value.times.length > 0) {
                        // _.each(value.times, (v) => {
                        value.times.forEach((v) => {
                            if (v.startTime > v.endTime) {
                                this.flag = false;
                                this.$notify({
                                    title: 'Warning',
                                    message: `Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc`,
                                    type: 'warning'
                                });
                            } else if (!v.startTime || !v.endTime ) {
                                this.flag = false;
                                this.$notify({
                                    title: 'Warning',
                                    message: `Thời gian bắt đầu và kết thúc không được để trống`,
                                    type: 'warning'
                                });
                            } else {
                                let temp = {
                                    event_date: value.day,
                                    start_time: v.startTime,
                                    end_time: v.endTime,
                                    start_date: value.day + ' ' +v.startTime,
                                    end_date: value.day + ' ' +v.endTime,
                                    created_name: this.currentUser.name
                                };
                                if (v.startTime >= "00:00" && v.startTime < "13:00") {
                                    temp.type = 'morning';
                                } else if (v.startTime >= "13:00" && v.startTime < "18:00") {
                                    temp.type = 'afternoon';
                                } else {
                                    temp.type = 'evening';
                                }
                                arr.push(temp);
                            }
                        }) 
                    }
                })
                if (this.flag) {
                    this.$store.dispatch(SCHEDULE_DUTY_STORE, { event_time: arr })
                        .then((response) => {
                            if (response.errors) {
                                this.$notify({
                                    title: 'Warning',
                                    message: `Serve đang bận, vui lòng thử lại sau: ${response.data.message}`,
                                    type: 'warning'
                                });
                            } else {
                                this.$message({
                                    type: 'success',
                                    message: 'Cập nhật thông tin thành công'
                                });
                                this.$emit('completed')  
                            }
                        });
                }  
            }
        },
        computed: {
            ...mapGetters(['currentUser'])
        }
    };

</script>